<template>
  <v-container
    v-if="examination"
    style="height: calc(100vh - 60px); overflow: auto"
    fluid
  >
    <v-card :loading="loading" class="pa-2">
      <!-- Title -->
      <v-card-title>
        {{ examination.name }}

        <span class="ml-auto" v-if="examination.endTime">
          Thời gian còn lại: {{ remaining }}
        </span>
        <span
          class="ml-auto"
          style="float:right"
          v-else-if="examination.startExamTime"
        >
          Thời gian đã làm: {{ time }}
        </span>
      </v-card-title>

      <!-- Actions -->
      <v-card-text class="pa-0">
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              placeholder="Tìm theo tên, mssv, email, lớp, nhóm học"
              dense
              clearable
              hide-details
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-btn
              small
              block
              color="secondary"
              :loading="loading"
              @click="fetchData"
              >Làm mới Danh sách</v-btn
            >
          </v-col>

          <v-col v-if="!examination.monitoringAssignment" cols="12" md="3">
            <v-btn
              small
              block
              color="primary"
              @click="formStudentManagementDialog = true"
            >
              Quản Lý Danh sách Sinh viên
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Items -->
      <v-card-text>
        <v-row>
          <v-col v-for="(form, i) of filteredForms" :key="i" cols="12" md="2">
            <v-card @click="showFormDetail(form)">
              <v-img
                v-if="!isNaN(form.id)"
                :src="loading ? imageLoading : `/api/forms/${form.id}/image`"
                :lazy-src="imageLoading"
              />

              <div v-else style="position: relative; background-color: grey;">
                <v-img :src="imageLoading" />

                <div
                  class="d-flex justify-center align-center"
                  style="font-size: 16px; color: white; width: 100%; height: 100%; position: absolute; top: 0; left: 0;"
                >
                  Chưa vào phòng
                </div>
              </div>

              <div v-if="form.imageSource === 'cam'" class="item-tag">
                Camera
              </div>
              <div v-else-if="form.imageSource === 'scr'" class="item-tag">
                Chụp màn hình
              </div>

              <v-card-title class="mb-3">
                {{ form.studentName }}
              </v-card-title>

              <v-card-subtitle class="mb-0">
                {{ form.studentCode }}
              </v-card-subtitle>

              <v-card-text class="d-flex align-center justify-space-between">
                <div title="Số câu đã làm" class="mr-4">
                  <v-icon class="mr-1">mdi-progress-question</v-icon>
                  <span>{{ form.status.currentQuestion }}</span>
                </div>

                <div title="Số đề" class="mr-4">
                  <v-icon class="mr-1">mdi-book</v-icon>
                  <span>{{ form.exam.code }}</span>
                </div>

                <div title="Số thứ tự" class="mr-4">
                  <v-icon class="mr-1">mdi-sort-numeric-ascending</v-icon>
                  <span>{{ form.order }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Form Detail Dialog -->
    <v-dialog v-if="currentForm" v-model="formDetailDialog" max-width="1280px">
      <v-card>
        <v-card-title class="font-weight-bold"
          >{{ currentForm.studentName }}
        </v-card-title>

        <v-card-subtitle class="ma-4 text-center">
          {{ currentForm.studentCode }} - {{ currentForm.email }} -
          {{ currentForm.ipAddress }}
        </v-card-subtitle>

        <v-row align="center" justify-md="center">
          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-progress-question</v-icon>
              <div class="mr-1">Số câu đã làm:</div>
              <div class="font-weight-bold">
                {{ currentForm.status.currentQuestion }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-book</v-icon>
              <div class="mr-1">Số đề:</div>
              <div class="font-weight-bold">
                {{ currentForm.exam.code }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-sort-numeric-ascending</v-icon>
              <div class="mr-1">Số thứ tự:</div>
              <div class="font-weight-bold">
                {{ currentForm.order }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-home</v-icon>
              <div class="mr-1">Mã lớp:</div>
              <div class="font-weight-bold">
                {{ currentForm.classId }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-account-group</v-icon>
              <div class="mr-1">Nhóm học:</div>
              <div class="font-weight-bold">
                {{ currentForm.studyGroupName }}
              </div>
            </v-row>
          </v-col>
        </v-row>

        <v-card-text class="pa-4">
          <v-img
            v-if="!isNaN(currentForm.id)"
            :src="loading ? imageLoading : `/api/forms/${currentForm.id}/image`"
            :lazy-src="imageLoading"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Students Management Dialog -->
    <RoomStudentManagementDialog
      v-if="!examination.monitoringAssignment"
      :selected-students="selectedStudents"
      :examination="examination"
      :visible.sync="formStudentManagementDialog"
      @change="onSelectedStudentsChanged"
    />
  </v-container>
</template>

<script>
import request from "@/utils/request";
import ServerItemSelect from "@/components/table/ServerItemSelect.vue";
import RoomStudentManagementDialog from "./RoomStudentManagementDialog.vue";
import { debounce } from "@/utils/debounce";
import ImageLoading from "@/assets/img-loading.png";
import dayjs from "dayjs";

export default {
  components: {
    RoomStudentManagementDialog,
    ServerItemSelect
  },

  data() {
    return {
      // Detail
      currentForm: undefined,
      formDetailDialog: false,

      // Monitoring Metadata
      examination: undefined,
      room: undefined,

      loading: false,

      // Student Management
      formStudentManagementDialog: false,

      // Forms
      selectedStudents: undefined,
      search: "",
      forms: [],

      //Time
      time: undefined,
      remaining: undefined,
      intervalTime: undefined,
      delta: 0
    };
  },

  computed: {
    filteredForms() {
      let forms = this.forms;

      if (this.selectedStudents && this.selectedStudents.length > 0) {
        forms = this.selectedStudents.map(s => {
          const form = this.forms.find(f => f.studentCode === s.studentCode);

          if (form) return form;

          const placeholderForm = { ...s, exam: {}, status: {} };
          delete placeholderForm.id;

          return placeholderForm;
        });
      }

      if (!this.search) return forms;

      const query = this.search.trim().toLowerCase();

      return forms.filter(f => {
        if (f.studentCode.toLowerCase().includes(query)) return true;

        if (f.email.toLowerCase().includes(query)) return true;

        if (f.studentName.toLowerCase().includes(query)) return true;

        if (f.classId.toLowerCase().includes(query)) return true;

        if (f.studyGroupName.toLowerCase().includes(query)) return true;

        return false;
      });
    },

    imageLoading() {
      return ImageLoading;
    }
  },

  async created() {
    this.init();
    const { data: timeServer } = await request({
      url: "/time",
      method: "get"
    });
    this.delta = dayjs(timeServer).diff(dayjs(), "s");
    this.intervalTime = setInterval(() => {
      this.getTime();
    }, 1000);
  },

  beforeDestroy() {
    this.destroy();
  },

  methods: {
    getDuration(time1, time2) {
      if (time1 < time2) {
        time1 = time2;
      }
      const seconds = time1.diff(time2, "s");
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },

    getTime() {
      if (this.examination) {
        const now = dayjs().add(this.delta, "s");
        if (this.examination.startExamTime) {
          this.time = this.getDuration(
            now,
            dayjs(this.examination.startExamTime)
          );
        }
        if (this.examination.endTime) {
          this.remaining = this.getDuration(
            dayjs(this.examination.endTime),
            now
          );
        }
      } else {
        this.time = undefined;
        this.remaining = undefined;
      }
    },

    init() {
      this.fetchMetadata();
      this.initIntervalFetch();
    },

    initIntervalFetch() {
      this.fetchData();
      this.intervalHandler = setInterval(() => {
        this.fetchData();
      }, 1 * 60 * 1000);
    },

    async fetchMetadata() {
      try {
        this.loading = true;

        const share = sessionStorage.getItem("share");
        if (!share) return this.$snackbar("Không có mã kíp thi", "error");
        const res = await request({
          url: "lecturer/supervisors/monitoring/metadata",
          params: { share }
        });

        this.examination = res.data.examination;
      } catch (error) {
        if (error.response.status === 403) {
          window.location = "/404";
          return;
        }
        this.$snackbar("Không có kíp thi nào!", "info", 10000);
      } finally {
        this.loading = false;
      }
    },

    destroy() {
      if (this.intervalHandler) {
        clearInterval(this.intervalHandler);
        this.intervalHandler = undefined;
      }
    },

    clear() {
      this.forms = [];
    },

    onQueryChanged: debounce(function() {
      this.fetchData();
    }, 500),

    async fetchData() {
      this.fetchMetadata();
      if (!this.examination) return;

      if (!this.examination.monitoringAssignment && !this.selectedStudents)
        return;

      try {
        this.loading = true;

        const res = await request({
          method: "POST",
          url: "/lecturer/supervisors/monitoring/forms",
          data: {
            studentCodes:
              this.selectedStudents &&
              this.selectedStudents.map(s => s.studentCode)
          },
          params: { share: sessionStorage.getItem("share") }
        });

        this.forms = res.data;
      } catch (error) {
        const message = error.response ? error.response.message : error.message;

        this.$snackbar(message, "error");
      } finally {
        this.loading = false;
      }
    },

    onSelectedStudentsChanged(students) {
      this.selectedStudents = students;
      this.fetchData();
    },

    showFormDetail(form) {
      this.currentForm = form;
      this.formDetailDialog = true;
    }
  },
  mounted() {},

  destroyed() {
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
    }
  }
};
</script>

<style scoped>
.item-tag {
  position: absolute;
  top: 2px;
  left: 4px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>